<template>
  <div class="animated fadeIn">
    <b-card-group class="mb-4">
      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-8 p-0 mr-2">
        <div slot="header">
          <strong> Filtros </strong>
          <b-button variant="primary" class="text-white float-right" size="xs" @click="filtrosAvancados()">Avançado</b-button>
        </div>
        <div class="p-1 mt-2">
          <b-row class="mb-1">
            <b-col sm="12" md="5" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.periodo" @change="changePeriodo" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Hoje">Hoje</b-form-radio>
                  <b-form-radio class="mx-0" value="Ontem">Ontem</b-form-radio>
                  <b-form-radio class="mx-0" value="Semana">Semana</b-form-radio>
                  <b-form-radio class="mx-0" value="Mes">Mês</b-form-radio>
                  <b-form-radio class="mx-0" value="Periodo">Período</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
              <multiselect v-model="filtro.fields.gestao" :options="filtro.listas.gestao" placeholder="Todas as Gestões" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                  <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
                </template>
                <template slot="clear">
                  <div class="multiselect__clear" v-if="filtro.fields.gestao.length" @mousedown.prevent.stop="clearAll('gestao')"></div>
                </template>
              </multiselect>
            </b-col>
            <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
              <multiselect v-model="filtro.fields.centres" :options="filtro.listas.centresFiltradosPorGestao" placeholder="Todas as Lojas" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                  <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
                </template>
                <template slot="clear">
                  <div class="multiselect__clear" v-if="filtro.fields.centres.length" @mousedown.prevent.stop="clearAll('centres')"></div>
                </template>
              </multiselect>
            </b-col>
            <b-col sm="4">
              <multiselect v-model="filtro.fields.formaVenda" :options="filtro.listas.formaVenda" placeholder="Todas as Formas de Venda" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                  <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
                </template>
                <template slot="clear">
                  <div class="multiselect__clear" v-if="filtro.fields.formaVenda.length" @mousedown.prevent.stop="clearAll('formaVenda')"></div>
                </template>
              </multiselect>
            </b-col>
            <!-- <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.gestao" :options="filtro.listas.gestao" @change="filterLojas"></b-form-select>
            </b-col>
            <b-col sm="4" class="pr-md-0 mb-1 mb-md-0">
              <b-form-select :plain="true" v-model="filtro.fields.centres" :options="filtro.listas.centres"></b-form-select>
            </b-col>
            <b-col sm="3">
              <b-form-select :plain="true" v-model="filtro.fields.formaVenda" :options="filtro.listas.formaVenda"></b-form-select>
            </b-col> -->
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6" sm="6" md="4" class="pr-md-0 pr-1 mb-1 mb-md-0">
              <b-form-input :plain="true" v-model="filtro.fields.dataIni" type="date" @change="dataChanged"></b-form-input>
            </b-col>
            <b-col cols="6" sm="6" md="4" class="pr-md-0 pl-1 pl-md-3 mb-1 mb-md-0">
              <b-form-input :plain="true" v-model="filtro.fields.dataFim" type="date" @change="dataChanged"></b-form-input>
            </b-col>
            <b-col sm="3">
              <b-form-select :plain="true" v-model="filtro.fields.tipoInformacao" :options="filtro.listas.tipoInformacao" :disabled="filtro.fields.exibicao === 'Tabelas'"></b-form-select>
            </b-col>
            <b-col sm="1" class="pl-md-1" v-if="!filtro.filtrosAvancados">
              <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
                <i class="fa fa-search"></i>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mb-1" v-if="filtro.filtrosAvancados">
            <b-col cols="6" sm="6" md="4" class="pr-md-0 pl-1 pl-md-3">
              <multiselect v-model="filtro.fields.vendedores" :options="filtro.listas.vendedores" placeholder="Todos os Vendedores" :multiple="true" :close-on-select="false" :clear-on-select="true" :show-labels="false" label="text" track-by="value">
                <template slot="selection" slot-scope="{ values, search, isOpen }">
                  <span v-if="values.length == 1 && !isOpen">{{ values.length }} opção selecionada</span>
                  <span v-if="values.length >= 2 && !isOpen">{{ values.length }} opções selecionadas</span>
                </template>
                <template slot="clear">
                  <div class="multiselect__clear" v-if="filtro.fields.vendedores.length" @mousedown.prevent.stop="clearAll('vendedores')"></div>
                </template>
              </multiselect>
            </b-col>
            <b-col sm="12" md="12" class="mt-2 mb-2">
              <div class="custom-control custom-checkbox pt-1">
                <input type="checkbox" class="custom-control-input" id="chkDesconsiderarPecas" v-model="filtro.fields.desconsiderarPecas">
                <label class="custom-control-label" for="chkDesconsiderarPecas">Desconsiderar devoluções em "Peças"</label>
              </div>
            </b-col>
            <b-col sm="12" md="10" lg="11" class="mt-2 mb-2">
              <div class="custom-control custom-checkbox pt-1">
                <input type="checkbox" class="custom-control-input" id="chkAtendimentoPorCliente" v-model="filtro.fields.atendimentoPorCliente">
                <label class="custom-control-label" for="chkAtendimentoPorCliente">Considerar atendimento único as vendas para o mesmo cliente</label>
              </div>
            </b-col>
            <b-col sm="1" md="1">
              <b-button variant="primary" class="my-2 my-sm-0 text-white" :class="{'btn-block': $mq === 'sm'}" @click="search()">
                <i class="fa fa-search"></i>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card
        header-tag="header"
        footer-tag="footer"
        class="col-md-4 p-0">
        <div slot="header">
          <strong> Layout </strong>
        </div>
        <div class="p-1">
          <b-row class="mb-1">
            <b-col sm="12" class="d-md-block">
              <b-button-toolbar class="float-left">
                <b-form-radio-group class="mr-3" buttons button-variant="outline-primary" v-model="filtro.fields.exibicao" @change="exibicaoChanged" name="radiosBtn">
                  <b-form-radio class="mx-0" value="Graficos">Gráficos</b-form-radio>
                  <b-form-radio class="mx-0" value="Tabelas">Tabelas</b-form-radio>
                </b-form-radio-group>
              </b-button-toolbar>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col sm="12">
              <b-form-select :plain="true" v-model="filtro.fields.agrupamento" :options="filtro.listas.agrupamentos" :disabled="filtro.fields.centres !== 0" @change="search"></b-form-select>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-card-group>

    <div v-if="filtro.fields.exibicao === 'Tabelas'">
      <b-card v-for="(item, index) in items" :key="index">
        <div slot="header" class="text-primary font-weight-bold">{{ item.name }}</div>
        <c-table :table-data="item.records.hora" :fields="extraFields.hora" striped small caption="Por Hora" header-class="text-black" :per-page="24" no-pagination></c-table>
        <c-table :table-data="item.records.semana" :fields="extraFields.dia" striped small caption="Por Dia da Semana" header-class="text-black" :per-page="7" no-pagination></c-table>
        <c-table :table-data="item.records.periodo" :fields="extraFields.periodo" striped small caption="Por Período" header-class="text-black" :per-page="31" no-pagination></c-table>
      </b-card>
    </div>

    <div v-if="filtro.fields.exibicao === 'Graficos'">
      <b-card v-for="(item, index) in items" :key="index">
        <div slot="header" class="font-weight-bold">{{ item.name }}</div>
        <b-card class="mr-md-2">
          <div slot="header" class="text-black">
            <div class="d-flex">
              <div class="d-flex-inline mr-auto">
                <strong>{{ tipoInformacaoSelected }} por Hora</strong>
              </div>
              <div class="d-flex-inline ml-auto">
                <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-bar-01', `${item.name} - Por Hora`)" title="Exportar"></i>
              </div>
            </div>
          </div>
          <bar :chartdata="item.charts.HORA" :height="350" :displayLegend="false" :moneyFormat="verifyTipoInformacao" chartId="chart-bar-01" v-if="loaded && displayChart(item, 'HORA')" />
          <div class="alert alert-secondary text-center mb-0" v-if="!displayChart(item, 'HORA')">
            Nenhum resultado encontrado.
          </div>
        </b-card>
        <b-card class="mr-md-2">
          <div slot="header" class="text-black">
            <div class="d-flex">
              <div class="d-flex-inline mr-auto">
                <strong>{{ tipoInformacaoSelected }} por Dia da Semana</strong>
              </div>
              <div class="d-flex-inline ml-auto">
                <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-bar-02', `${item.name} - Por Dia da Semana`)" title="Exportar"></i>
              </div>
            </div>
          </div>
          <bar :chartdata="item.charts.SEMANA" :height="350" :displayLegend="false" :moneyFormat="verifyTipoInformacao" chartId="chart-bar-02" v-if="loaded && displayChart(item, 'SEMANA')" />
          <div class="alert alert-secondary text-center mb-0" v-if="!displayChart(item, 'SEMANA')">
            Nenhum resultado encontrado.
          </div>
        </b-card>
        <b-card>
          <div slot="header" class="text-black">
            <div class="d-flex">
              <div class="d-flex-inline mr-auto">
                <strong>{{ tipoInformacaoSelected }} por Período</strong>
              </div>
              <div class="d-flex-inline ml-auto">
                <i class="fa fa-download text-primary cursor-pointer" @click="export2image('chart-bar-03', `${item.name} - Por Período`)" title="Exportar"></i>
              </div>
            </div>
          </div>
          <bar :chartdata="item.charts.PERIODO" :height="350" :displayLegend="false" :moneyFormat="verifyTipoInformacao" chartId="chart-bar-03" v-if="loaded && displayChart(item, 'PERIODO')" />
          <div class="alert alert-secondary text-center mb-0" v-if="!displayChart(item, 'PERIODO')">
            Nenhum resultado encontrado.
          </div>
        </b-card>
      </b-card>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import API from '@/core/api';
import cTable from '@/core/components/Table.vue'
import Multiselect from 'vue-multiselect'
import Bar from '@/core/components/charts/Bar';
import {diaSemana, mes} from '@/shared/string';
import session from '@/shared/session'
import lojasPorGestaoMultiMixin from '@/core/mixins/lojasPorGestaoMulti.mixin.js'

export default {
  name: 'FluxoAtendimentos',
  components: {
    cTable,
    Multiselect,
    Bar
  },
  mixins: [
    lojasPorGestaoMultiMixin
  ],
  data: () => {
    return {
      loaded: false,
      filtro: {
        listas: {
          gestao: session.get('gestoes').filter(c => c.value !== 0),
          centres: session.get('lojas').filter(c => c.value !== 0),
          centresDefault: session.get('lojas'),
          formaVenda: session.get('formas').filter(c => c.value !== 0),
          vendedores: session.get('vendedores'),
          agrupamentos: [{value: 1, text: 'Gestão'}, {value: 2, text: 'Grupo Empresarial'}],
          tipoInformacao: [{value: null, text: 'Todos', disabled: true}, {value: 'vendas', text: 'Vendas'}, {value: 'pecas', text: 'Peças'}, {value: 'atendimentos', text: 'Atendimentos'}, {value: 'vp', text: 'V/P'}, {value: 'va', text: 'V/A'}, {value: 'pa', text: 'P/A'}, {value: 7, text: 'Taxa de Conversão'}, {value: 'descontos', text: 'Descontos'}], //{value: 'clientes', text: 'Clientes'},
          centresFiltradosPorGestao: []
        },
        fields: {
          periodo: 'Hoje',
          gestao: [],
          centres: [],
          formaVenda: [],
          vendedores: [],
          dataIni: moment().format('YYYY-MM-DD'),
          dataFim: moment().format('YYYY-MM-DD'),
          tipoInformacao: 'vendas',
          exibicao: 'Graficos',
          agrupamento: 1,
          desconsiderarPecas: false,
          atendimentoPorCliente: false
        },
        filtrosAvancados: false
      },
      items: [],
      fields: [
        {key: 'vendas', label: 'Vendas R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'pecas', label: 'Peças', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'atendimentos', label: 'Atendimentos', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'vp', label: 'V/P R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'va', label: 'V/A R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'pa', label: 'P/A', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'clientes', label: 'Clientes', sortable: true, number: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'taxa_conversao', label: 'Tx. Conv. %', sortable: true, numeric: true, thClass: 'text-right', tdClass: 'text-right'},
        {key: 'descontos', label: 'Descontos R$', sortable: true, currency: true, thClass: 'text-right', tdClass: 'text-right'}
      ],
      charts: {},
      tipoInformacaoSelected: ''
    }
  },
  computed: {
    extraFields: function () {
      return {
        hora: [{key: 'exibicao', label: 'Hora', sortable: true}].concat(this.fields.filter(f => f.key !== 'clientes')),
        dia: [{key: 'exibicao', label: 'Dia da Semana', sortable: true}].concat(this.fields),
        periodo: [{key: 'exibicao', label: 'Período', sortable: true}].concat(this.fields)
      }
    },
    verifyTipoInformacao() {
      const isCurrency = ['vendas', 'vp', 'va', 'descontos'];
      return isCurrency.includes(this.filtro.fields.tipoInformacao) ? true : false
    }
  },
  methods: {
    async search() {
      try {
        if (this.validaPeriodo() === true) {
          this.loaded = false;
          
          if (this.filtro.fields.tipoInformacao === null) {
            this.filtro.fields.tipoInformacao = 'vendas';
          }
          this.tipoInformacaoSelected = this.filtro.listas.tipoInformacao.find(i => i.value && i.value === this.filtro.fields.tipoInformacao).text;

          const fluxoAPI = new API('api/fluxo-atendimento');
          const {data} = await fluxoAPI.get({ ...this.parseFilters(this.filtro.fields) });
          this.items = data;

          const { centres, agrupamento } = this.filtro.fields
          if (centres === 0) {
            this.aplicarAgrupamento();
          }

          this.loadCharts();
        }
      } catch (e) {
        console.error(e)
      }
    },
    aplicarAgrupamento() {
      const { agrupamento } = this.filtro.fields

      if ([1].includes(agrupamento)) {
        let group = []
        let gestoes = this.filtro.listas.gestao.filter(g => g.value && g.value !== 0)
        let _group = {
          hora: _.groupBy(this.items[0].records.hora, 'gestao'),
          periodo: _.groupBy(this.items[0].records.periodo, 'gestao'),
          semana: _.groupBy(this.items[0].records.semana, 'gestao')
        }
        gestoes.forEach(g => {
          if (_group.hora[g.value] || _group.periodo[g.value] || _group.semana[g.value]) {
            group.push({
              name: _group.hora[g.value][0].gestao_desc,
              records: {
                hora: [ ..._group.hora[g.value] ],
                periodo: [ ..._group.periodo[g.value] ],
                semana: [ ..._group.semana[g.value] ]
              }
            })
          }
        })

        if (group.length > 0) {
          this.items = group
        }
      } else if ([2].includes(agrupamento)) {
        this.items[0].name = 'Grupo Empresarial'
        const { hora, periodo, semana } = this.items[0].records
        this.items[0].records = {
          hora: _(hora).groupBy('exibicao').map((h, id) => ({
            exibicao: id,
            vendas: _.sumBy(h, function(v) { return parseFloat(v.vendas) }),
            descontos: _.sumBy(h, function(v) { return parseFloat(v.descontos) }),
            atendimentos: _.sumBy(h, function(v) { return Number(v.atendimentos || 0) }),
            // clientes: _.sumBy(h, function(v) { return Number(v.clientes || 0) }),
            pecas: _.sumBy(h, function(v) { return Number(v.pecas || 0) }),
            pa: _.sumBy(h, function(v) { return parseFloat(v.pa) }),
            va: _.sumBy(h, function(v) { return parseFloat(v.va) }),
            vp: _.sumBy(h, function(v) { return parseFloat(v.vp) }),
          })).value(),

          periodo: _(periodo).groupBy('exibicao').map((h, id) => ({
            exibicao: id,
            vendas: _.sumBy(h, function(v) { return parseFloat(v.vendas) }),
            descontos: _.sumBy(h, function(v) { return parseFloat(v.descontos) }),
            atendimentos: _.sumBy(h, function(v) { return Number(v.atendimentos || 0) }),
            clientes: _.sumBy(h, function(v) { return Number(v.clientes || 0) }),
            pecas: _.sumBy(h, function(v) { return Number(v.pecas || 0) }),
            pa: _.sumBy(h, function(v) { return parseFloat(v.pa) }),
            va: _.sumBy(h, function(v) { return parseFloat(v.va) }),
            vp: _.sumBy(h, function(v) { return parseFloat(v.vp) }),
          })).value(),

          semana: _(semana).groupBy('exibicao').map((h, id) => ({
            exibicao: id,
            vendas: _.sumBy(h, function(v) { return parseFloat(v.vendas) }),
            descontos: _.sumBy(h, function(v) { return parseFloat(v.descontos) }),
            atendimentos: _.sumBy(h, function(v) { return Number(v.atendimentos || 0) }),
            clientes: _.sumBy(h, function(v) { return Number(v.clientes || 0) }),
            pecas: _.sumBy(h, function(v) { return Number(v.pecas || 0) }),
            pa: _.sumBy(h, function(v) { return parseFloat(v.pa) }),
            va: _.sumBy(h, function(v) { return parseFloat(v.va) }),
            vp: _.sumBy(h, function(v) { return parseFloat(v.vp) }),
          })).value(),
        }
      }
    },
    validaPeriodo() {
      const { dataIni, dataFim } = this.filtro.fields;
      let invalid = false;

      if (
        dataIni === '' ||
        dataFim === '' ||
        moment(dataIni).isAfter(moment(dataFim)) ||
        !moment(dataIni).isValid() ||
        !moment(dataFim).isValid()
      ) {
        invalid = true;
      }

      if (invalid) {
        return swal({
          text: 'Data inválida.'
        });
      }
      return true
    },
    loadCharts() {
      this.filtro.listas.tipoInformacao[0].disabled = true;
      const { tipoInformacao } =  this.filtro.fields;

      this.items.forEach(items => {
        items.charts = {
          HORA: {
            labels: items.records.hora.map(r => r.exibicao).join().split(','),
            datasets: [
              {
                label: 'Por Hora',
                backgroundColor: '#ffc107',
                data: items.records.hora.map(r => Number(r[tipoInformacao])).join().split(',')
              }
            ]
          },
          SEMANA: {
            labels: items.records.semana.map(r => {
              r.exibicao = diaSemana(r.exibicao);
              return r.exibicao;
            }).join().split(','),
            datasets: [
              {
                label: 'Por Dia da Semana',
                backgroundColor: '#f86c6b',
                data: items.records.semana.map(r => Number(r[tipoInformacao])).join().split(',')
              }
            ]
          },
          PERIODO: {
            labels: items.records.periodo.map(r => {
              const porMes = r.exibicao.split('/');
              if (porMes.length > 1) {
                r.exibicao = `${mes(porMes[0])}/${porMes[1]}`;
              }
              return r.exibicao;
            }).join().split(','),
            datasets: [
              {
                label: 'Por Período',
                backgroundColor: '#63c2de',
                data: items.records.periodo.map(r => Number(r[tipoInformacao])).join().split(',')
              }
            ]
          }
        }
      })

      this.loaded = true;
    },
    dataChanged() {
      this.filtro.fields.periodo = 'Periodo';
    },
    exibicaoChanged() {
      const {exibicao} = this.filtro.fields;
      if (exibicao === 'Graficos') {
        this.filtro.listas.tipoInformacao[0].disabled = false;
        this.filtro.fields.tipoInformacao = null;
      } else {
        this.filtro.fields.tipoInformacao = this.filtro.listas.tipoInformacao.find(i => i.text && i.text === this.tipoInformacaoSelected).value;
      }
    },
    changePeriodo(periodo) {
      switch(periodo) {
        case 'Hoje': {
          this.filtro.fields.dataIni = moment().format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().format('YYYY-MM-DD');
          break;
        }
        case 'Ontem': {
          this.filtro.fields.dataIni = moment().subtract(1, 'day').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().subtract(1, 'day').format('YYYY-MM-DD');
          break;
        }
        case 'Semana': {
          this.filtro.fields.dataIni = moment().startOf('week').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('week').format('YYYY-MM-DD');
          break;
        }
        case 'Mes': {
          this.filtro.fields.dataIni = moment().startOf('month').format('YYYY-MM-DD');
          this.filtro.fields.dataFim = moment().endOf('month').format('YYYY-MM-DD');
          break;
        }
        default: return;
      }
      this.search();
    },
    displayChart(item, tipo) {
      if (item.charts[tipo].datasets) {
        const data = item.charts[tipo].datasets[0].data
        if (data.length === 0 || data[0] === '') {
          return false
        }
      }
      return true
    },
    parseFilters(filters) {
      let filter = { ...filters };
      const {
        gestao,
        centres,
        formaVenda,
        vendedores
      } = filters;

      filter.gestao       = gestao.length === 0 ? [] : gestao;
      filter.centres      = centres.length === 0 ? [] : centres;
      filter.formaVenda   = formaVenda.length === 0 ? [] : formaVenda;

      filter.vendedores   = vendedores.length === 0 ? [] : vendedores;

      filter.gestao       = (filter.gestao && filter.gestao.map(c => c.value).join(',')) || '0';
      filter.centres      = (filter.centres && filter.centres.map(c => c.value).join(',')) || '0';
      filter.formaVenda   = (filter.formaVenda && filter.formaVenda.map(c => c.value).join(',')) || '0';

      filter.vendedores   = (filter.vendedores && filter.vendedores.map(c => c.value).join(',')) || '0';

      return filter;
    },
    export2image: function (id, name) {
      // Set White Background
      let chart = document.getElementById(id)
      var ctx = chart.getContext("2d");
      ctx.save();
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = "#FFF";
      ctx.fillRect(0, 0, chart.width, chart.height);
      ctx.restore();

      // Download image
      let canvas = document.getElementById(id).toDataURL('image/png')
      let link = document.createElement('a')
      link.download = name || 'Grafico'
      link.href = canvas
      link.click()
    },
    filterLojas() {
      const { gestao, centres } = this.filtro.fields;
      const { centresDefault } = this.filtro.listas;
      this.filtro.listas.centres = gestao === 0 ? centresDefault
        : centresDefault.filter(l => l.gestao === gestao || l.value === 0)

      const gestaoCentres = centresDefault.find(c => c.value === centres)
      if (gestaoCentres.gestao !== gestao) {
        this.filtro.fields.centres = 0
      }
    },
    filtrosAvancados() {
      if (this.filtro.filtrosAvancados) {
        this.filtro.fields.grupos = [];
        this.filtro.fields.subgrupos = [];
        this.filtro.fields.fornecedores = [];
        this.filtro.fields.vendedores = [];
      }

      setTimeout(() => {this.filtro.filtrosAvancados = !this.filtro.filtrosAvancados},200);
    },
    clearAll(field) {
      if (this.filtro.fields[field] && this.filtro.fields[field].length > 0) {
        this.filtro.fields[field] = [];
      }
    }
  },
  created() {
    this.search();
  }
}
</script>
